
































































































































































































































































































































































































































import { COUNTRIES } from '@/shared/constants'
import { IUser, User, UserRole } from '@/shared/model/user.model'
import _ from 'lodash'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { nanoid } from 'nanoid'
import userService from '@/shared/services/userService'
import { ICompany } from '@/shared/model/company.model'
import companyService from '@/shared/services/companyService'

export default Vue.extend({
  components: {
    ImageUpload: () => import('../_components/ImageUpload.vue'),
    ReportsTable: () => import('../report/ReportsTable.vue'),
    CertificatesTable: () => import('../certificate/CertificatesTable.vue'),
  },
  data: () => ({
    user: new User(),
    usersCompany: undefined as undefined | ICompany,
    image: null as any | string,
    imageContentType: null as any | string,
    signatureImage: null as any | string,
    signatureImageContentType: null as any | string,
    userFromDB: new User() as IUser,
    countries: COUNTRIES,
    customRules: 'required',
    userRoles: [UserRole.admin, UserRole.user],

    birthdayDatePickerVisible: false,
    birthdayVal: undefined,

    completeUserProfileDialog: false,
    breadcrumbItems: [] as any[],

    blobContent: { image: null } as any,
    signatureBlobContent: { signatureImage: null } as any,
    customEmailRules: 'email|checkemail',
  }),
  computed: {
    maxDate () {
      const now = new Date()
      const date10YearsAgo = new Date()
      date10YearsAgo.setFullYear(now.getFullYear() - 10)
      return date10YearsAgo.toISOString()
    },
    userIsSystemAdmin () {
      return userService.userIsSystemAdmin()
    },
  },
  watch: {
    user: function () {
      if (this.user && this.user.metadata && this.user.metadata.image) {
        this.image = `/api/file/${this.user.metadata.image}`
      }
      if (this.user && this.user.metadata && this.user.metadata.signature) {
        this.signatureImage = `/api/file/${this.user.metadata.signature}`
      }
      if (this.account() && this.account().roles && this.account().roles.includes(UserRole.admin) && this.user._id) {
        companyService.getUsersCompany(this.user._id).then((result) => {
          if (result && result.data) {
            this.usersCompany = result.data[0]
          }
        })
      }
      if(this.user && this.user.email){
        this.customEmailRules = `email|checkemail:${this.userFromDB.email}`
      }
    },
    $route: function () {
      this.breadcrumbItems = []
      this.getUser()
    },
    birthdayDatePickerVisible (value) {
      value &&
        this.$nextTick(() => {
          const picker = this.$refs.pickerInDialog as any
          if (picker && picker.internalActivePicker) {
            picker.internalActivePicker = 'YEAR'
          }
        })
    },
  },
  mounted () {
    this.getUser()
    const query: any = this.$route.query
    if (query.message && query.message === 'incomplete') {
      this.completeUserProfileDialog = true
    }
  },
  methods: {
    ...mapGetters({
      account: 'account',
      certificationAuthority: 'selectedCertificationAuthority',
    }),
    ...mapState('userStore', {
      updateUserError: 'error',
    }),
    ...mapActions('userStore', {
      updateUser: 'updateItem',
    }),
    async clickSave (valid: any) {
      await this.updateUser({ id: this.user._id, item: this.user })

      if (this.updateUserError()) {
        this.$toast.error(this.$t('dialog.user-profile-update-failed').toString())
      } else {
        await userService.getMyself()
        this.getUser()
        this.imageContentType = null
        this.signatureImageContentType = null
        this.$toast(this.$t('dialog.user-profile-update-successfull').toString())
      }
    },
    dataChanged () {
      return !_.isEqual(this.userFromDB, this.user)
    },
    getUser () {
      const uuid = this.$route.params.uuid
      if (uuid) {
        userService.getUser(uuid).then((user: IUser) => {
          this.user = user
          this.userFromDB = _.cloneDeep(user)
          this.breadcrumbItems = [
            {
              text: this.$t('users'),
              to: '/app/users',
              exact: true,
            },
            {
              text: `${this.user.metadata.firstName} ${this.user.metadata.lastName}`,
            },
          ]
        })
      } else {
        this.user = this.$store.getters.account
        this.userFromDB = _.cloneDeep(this.user)
      }
    },
    setImage: function (blobContent: any) {
      if (blobContent && blobContent.image) {
        this.image = blobContent.image
        if (blobContent.imageContentType) {
          this.imageContentType = blobContent.imageContentType
        } else {
          this.imageContentType = null
        }
        if (this.user.metadata) {
          this.user.metadata.image = blobContent.image
        }
      }
    },
    setSignatureImage: function (signatureBlobContent: any) {
      if (signatureBlobContent && signatureBlobContent.image) {
        this.signatureImage = signatureBlobContent.image
        if (signatureBlobContent.imageContentType) {
          this.signatureImageContentType = signatureBlobContent.imageContentType
        } else {
          this.signatureImageContentType = null
        }
        if (this.user.metadata) {
          this.user.metadata.signature = signatureBlobContent.image
        }
      }
    },
    processCustomRules () {
      if (this.userFromDB.username === this.user.username) {
        this.customRules = 'required'
      } else {
        this.customRules = 'required|checkusername'
      }
    },
    selectDate () {
      this.birthdayDatePickerVisible = false
      if (this.user.metadata) this.user.metadata.birthday = this.birthdayVal
    },
    clickNewPassword () {
      this.user.password = nanoid(8)
    },
    dateIsOlderThan10Years (val: any) {
      return userService.dateIsOlderThan10Years(val)
    },
  },
})
